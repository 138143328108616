import { Application } from "@hotwired/stimulus"



const application = Application.start()


export { application }



